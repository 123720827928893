import VueI18n, { createI18n } from "vue-i18n";
import { useHead } from "unhead";
import { createVueRouter } from "@/router";
import { fetchTranslation } from "@/api/i18n";
import langMap, { defaultLang } from "@/constant/lang";

const FALLBACK_LOCALE = langMap[defaultLang];

export const i18n: VueI18n.I18n = createI18n({
  locale: "",
  legacy: true,
  // global: true,
  allowComposition: true,
  globalInjection: true,
  fallbackLocale: FALLBACK_LOCALE.value,
  messages: {},
});
let loadedLangs: string[];

export async function initI18n() {
  const router = createVueRouter();
  const currentRoute = router.resolve(window.location.pathname);
  let langKey = currentRoute.params.lang as string;
  if (!langMap[langKey]) {
    langKey = defaultLang;
  }
  const lang = "zh_hans";
  const fallbackLang: string = FALLBACK_LOCALE.value;
  if (lang === fallbackLang) {
    loadedLangs = [lang];
    const data = await fetchTranslation(lang);
    i18n.global.mergeLocaleMessage(lang, data);
  } else {
    loadedLangs = [lang, fallbackLang];
    const [data, fallbackData] = await Promise.all([
      fetchTranslation(lang),
      fetchTranslation(fallbackLang),
    ]);

    i18n.global.mergeLocaleMessage(lang, data);
    i18n.global.mergeLocaleMessage(fallbackLang, fallbackData);
  }

  i18n.global.locale = lang;
  langKey = langKey.replace("hant", "Hant").replace("hans", "Hans");
  useHead({
    htmlAttrs: {
      lang: langKey,
    },
  });
}

export async function switchLocale(langKey: string): Promise<VueI18n.I18n> {
  if (!i18n) {
    console.warn("i18n is not ready.");
    return i18n;
  }
  const lang = langMap[langKey] || FALLBACK_LOCALE;
  if (!loadedLangs.includes(lang)) {
    loadedLangs.push(lang);
    const translation = await fetchTranslation(lang.value);
    i18n.global.mergeLocaleMessage(langKey, translation);
  }
  // debugger;
  i18n.global.locale = langKey;
  langKey = langKey.replace("hant", "Hant").replace("hans", "Hans");
  useHead({
    htmlAttrs: {
      lang: langKey,
    },
  });
  return i18n;
}
